import React from "react"

export default () => {
  return(
    <svg
      id="meo-DoorLeft"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 163 386"
      width="163"
      height="386"
    >
      <defs>
        <style>
         {`
          .doorleft-cls-1 {
            fill: #ededed;
          }

          .doorleft-cls-2 {
            fill: #ccc;
          }

          .doorleft-cls-3 {
            fill: url(#doorLeft_gradient_91);
          }

          .doorleft-cls-4 {
            fill: #fff;
          }

          .doorleft-cls-5 {
            fill: url(#doorLeft_gradient_55);
          }

          .doorleft-cls-6 {
            fill: url(#doorLeft_gradient_10);
          }

          .doorleft-cls-7 {
            fill: url(#doorLeft_gradient_10-2);
          }

          .doorleft-cls-8 {
            fill: url(#doorLeft_gradient_10-3);
          }

          .doorleft-cls-9 {
            fill: url(#doorLeft_gradient_97);
          }

          .doorleft-cls-10 {
            fill: #bebebe;
          }

          .doorleft-cls-11 {
            fill: url(#doorLeft_gradient_97-2);
          }
          `}
        </style>
        <linearGradient id="doorLeft_gradient_91" data-name="Namnlös övertoning 91" x1="47.64" y1="117.36" x2="118.36" y2="46.64" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#bebebe"/>
          <stop offset="0.19" stopColor="#dedede"/>
          <stop offset="0.38" stopColor="#f6f6f6"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="0.62" stopColor="#f6f6f6"/>
          <stop offset="0.81" stopColor="#dedede"/>
          <stop offset="1" stopColor="#bebebe"/>
        </linearGradient>
        <linearGradient id="doorLeft_gradient_55" data-name="Namnlös övertoning 55" x1="83" y1="121" x2="83" y2="43" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#66ccd2" stopOpacity="0.3"/>
          <stop offset="1" stopColor="#66ccd2"/>
        </linearGradient>
        <linearGradient id="doorLeft_gradient_10" data-name="Ny övertoningsruta 10" x1="58.34" y1="57.34" x2="107.66" y2="106.66" gradientTransform="translate(82.29 -34.67) rotate(45)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff" stopOpacity="0"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="1" stopColor="#fff" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="doorLeft_gradient_10-2" x1="66.28" y1="80.61" x2="93.81" y2="108.14" gradientTransform="translate(90.18 -28.96) rotate(45)" xlinkHref="#doorLeft_gradient_10"/>
        <linearGradient id="doorLeft_gradient_10-3" x1="72.19" y1="55.86" x2="99.72" y2="83.39" gradientTransform="translate(74.41 -40.39) rotate(45)" xlinkHref="#doorLeft_gradient_10"/>
        <linearGradient id="doorLeft_gradient_97" data-name="Namnlös övertoning 97" x1="0" y1="70" x2="6" y2="70" xlinkHref="#doorLeft_gradient_91"/>
        <linearGradient id="doorLeft_gradient_97-2" data-name="Namnlös övertoning 97" x1="0" y1="316" x2="6" y2="316" xlinkHref="#doorLeft_gradient_91"/>
      </defs>
      <rect className="doorleft-cls-1" x="3" width="160" height="386"/>
      <rect className="doorleft-cls-2" x="136" y="160" width="20" height="55" rx="2.8" ry="2.8"/>
      <g>
        <circle className="doorleft-cls-3" cx="83" cy="82" r="50"/>
        <circle className="doorleft-cls-4" cx="83" cy="82" r="39"/>
        <circle className="doorleft-cls-5" cx="83" cy="82" r="39"/>
        <g>
          <rect className="doorleft-cls-6" x="79.11" y="47.12" width="7.78" height="69.76" transform="translate(-33.67 82.71) rotate(-45)"/>
          <rect className="doorleft-cls-7" x="78.38" y="74.91" width="3.33" height="38.93" transform="translate(-43.29 84.24) rotate(-45)"/>
          <rect className="doorleft-cls-8" x="84.29" y="50.16" width="3.33" height="38.93" transform="translate(-24.05 81.17) rotate(-45)"/>
        </g>
      </g>
      <rect className="doorleft-cls-2" x="3" y="310" width="160" height="76"/>
      <g>
        <rect className="doorleft-cls-9" y="58" width="6" height="24" rx="1" ry="1"/>
        <rect className="doorleft-cls-10" y="69.25" width="6" height="1.5"/>
      </g>
      <g>
        <rect className="doorleft-cls-11" y="304" width="6" height="24" rx="1" ry="1"/>
        <rect className="doorleft-cls-10" y="315.25" width="6" height="1.5"/>
      </g>
    </svg>
  )
}
