import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

const DoorRight = styled.svg`
  perspective: 500px;
  transform-style: preserve-3d;
  transform-origin: right;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 500ms;

  ${props => props.isOpened ? `
    animation-name: openTheDoor;
  ` : `
    animation-name: none;
  `}

  #indicator_closed {
    opacity: ${props => props.isOpened ? 0 : 1};
  }

  #indicator_open {
    opacity: ${props => props.isOpened ? 1 : 0};
  }

  #key { cursor:pointer; }

  @keyframes openTheDoor {
    from { transform: rotateY(0); }
    to { transform: rotateY(35deg); }
  }
`

export default ({ onClick, isOpened, ...props }) => {
  const intl = useIntl()
  const handleKeyPress = (event) => {
    if (
      event.charCode == 32 || // Space
      event.charCode == 13 // Enter
    ) {
      onClick()
    }
  }

  return(
    <DoorRight
      isOpened={isOpened}
      id="meo-DoorRight"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 163 386"
      width="163"
      height="386"
      role="button"
      onClick={onClick}
      onKeyPress={handleKeyPress}
    >
      <defs>
        <style>
        {`
          .doorright-cls-1 {
            fill: #ededed;
          }

          .doorright-cls-2 {
            fill: #ccc;
          }

          .doorright-cls-3 {
            fill: url(#doorRight_gradient_91);
          }

          .doorright-cls-4 {
            fill: #fff;
          }

          .doorright-cls-5 {
            fill: url(#doorRight_gradient_55);
          }

          .doorright-cls-6 {
            fill: url(#doorRight_gradient_10);
          }

          .doorright-cls-7 {
            fill: url(#doorRight_gradient_10-2);
          }

          .doorright-cls-8 {
            fill: url(#doorRight_gradient_10-3);
          }

          .doorright-cls-9 {
            fill: url(#doorRight_gradient_97);
          }

          .doorright-cls-10 {
            fill: #bebebe;
          }

          .doorright-cls-11 {
            fill: url(#doorRight_gradient_97-2);
          }

          .doorright-cls-12 {
            fill: url(#doorRight_gradient_72);
          }

          .doorright-cls-13 {
            fill: #33bd00;
          }

          .doorright-cls-14 {
            fill: url(#doorRight_gradient_6);
          }

          .doorright-cls-15 {
            fill: url(#doorRight_gradient_39);
          }

          .doorright-cls-16 {
            fill: url(#doorRight_gradient_8);
          }
        `}
        </style>
        <linearGradient id="doorRight_gradient_91" data-name="Namnlös övertoning 91" x1="44.64" y1="117.36" x2="115.36" y2="46.64" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#bebebe"/>
          <stop offset="0.19" stopColor="#dedede"/>
          <stop offset="0.38" stopColor="#f6f6f6"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="0.62" stopColor="#f6f6f6"/>
          <stop offset="0.81" stopColor="#dedede"/>
          <stop offset="1" stopColor="#bebebe"/>
        </linearGradient>
        <linearGradient id="doorRight_gradient_55" data-name="Namnlös övertoning 55" x1="80" y1="121" x2="80" y2="43" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#66ccd2" stopOpacity="0.3"/>
          <stop offset="1" stopColor="#66ccd2"/>
        </linearGradient>
        <linearGradient id="doorRight_gradient_10" data-name="Ny övertoningsruta 10" x1="55.34" y1="57.34" x2="104.66" y2="106.66" gradientTransform="translate(81.41 -32.55) rotate(45)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff" stopOpacity="0"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="1" stopColor="#fff" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="doorRight_gradient_10-2" x1="63.28" y1="80.61" x2="90.81" y2="108.14" gradientTransform="translate(89.3 -26.84) rotate(45)" xlinkHref="#doorRight_gradient_10"/>
        <linearGradient id="doorRight_gradient_10-3" x1="69.19" y1="55.86" x2="96.72" y2="83.39" gradientTransform="translate(73.53 -38.27) rotate(45)" xlinkHref="#doorRight_gradient_10"/>
        <linearGradient id="doorRight_gradient_97" data-name="Namnlös övertoning 97" x1="157" y1="70" x2="163" y2="70" xlinkHref="#doorRight_gradient_91"/>
        <linearGradient id="doorRight_gradient_97-2" data-name="Namnlös övertoning 97" x1="157" y1="316" x2="163" y2="316" xlinkHref="#doorRight_gradient_91"/>
        <linearGradient id="doorRight_gradient_72" data-name="Namnlös övertoning 72" x1="33.21" y1="180.49" x2="126.79" y2="234.51" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="1" stopColor="#999"/>
        </linearGradient>
        <linearGradient id="doorRight_gradient_6" data-name="Namnlös övertoning 6" x1="73.64" y1="180.11" x2="86.36" y2="167.39" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#ccc"/>
          <stop offset="0.14" stopColor="#dedede"/>
          <stop offset="0.36" stopColor="#f6f6f6"/>
          <stop offset="0.5" stopColor="#fff"/>
          <stop offset="0.64" stopColor="#f6f6f6"/>
          <stop offset="0.86" stopColor="#dedede"/>
          <stop offset="1" stopColor="#ccc"/>
        </linearGradient>
        <radialGradient id="doorRight_gradient_39" data-name="Namnlös övertoning 39" cx="80" cy="173.75" r="7" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="red"/>
          <stop offset="0.23" stopColor="#fb0000"/>
          <stop offset="0.47" stopColor="#e00"/>
          <stop offset="0.72" stopColor="#da0000"/>
          <stop offset="0.96" stopColor="#bd0000"/>
          <stop offset="1" stopColor="#b80000"/>
        </radialGradient>
        <radialGradient id="doorRight_gradient_8" data-name="Ny övertoningsruta 8" cx="80" cy="173.75" r="7" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#33bd00"/>
          <stop offset="0.26" stopColor="#34b903"/>
          <stop offset="0.53" stopColor="#37ac0d"/>
          <stop offset="0.8" stopColor="#3d981c"/>
          <stop offset="1" stopColor="#42842b"/>
        </radialGradient>
      </defs>
      <title>{intl.formatMessage({ id: "meo_doorsEnter_alt" })}</title>
      <a xlinkHref="#meo-DoorRight">
        <rect className="doorright-cls-1" width="160" height="386"/>
        <rect className="doorright-cls-2" x="7" y="160" width="20" height="55" rx="2.8" ry="2.8"/>
        <g>
          <circle className="doorright-cls-3" cx="80" cy="82" r="50"/>
          <circle className="doorright-cls-4" cx="80" cy="82" r="39"/>
          <circle className="doorright-cls-5" cx="80" cy="82" r="39"/>
          <g>
            <rect className="doorright-cls-6" x="76.11" y="47.12" width="7.78" height="69.76" transform="translate(-34.55 80.59) rotate(-45)"/>
            <rect className="doorright-cls-7" x="75.38" y="74.91" width="3.33" height="38.93" transform="translate(-44.17 82.12) rotate(-45)"/>
            <rect className="doorright-cls-8" x="81.29" y="50.16" width="3.33" height="38.93" transform="translate(-24.93 79.05) rotate(-45)"/>
          </g>
        </g>
        <rect className="doorright-cls-2" y="310" width="160" height="76"/>
        <g>
          <rect className="doorright-cls-9" x="157" y="58" width="6" height="24" rx="1" ry="1"/>
          <rect className="doorright-cls-10" x="157" y="69.25" width="6" height="1.5"/>
        </g>
        <g>
          <rect className="doorright-cls-11" x="157" y="304" width="6" height="24" rx="1" ry="1"/>
          <rect className="doorright-cls-10" x="157" y="315.25" width="6" height="1.5"/>
        </g>
        <g id="key">
          <rect className="doorright-cls-12" x="42.5" y="160" width="75" height="95" rx="6" ry="6"/>
          <rect x="50" y="187.5" width="60" height="60" rx="4" ry="4"/>
          <path className="doorright-cls-13" d="M80,238.56a10.44,10.44,0,0,1-4.1-20V200.87a1.41,1.41,0,0,1,.41-1.09l3.1-3.1a0.8,0.8,0,0,1,.6-0.24,0.77,0.77,0,0,1,.59.26l3.27,3.45a0.84,0.84,0,0,1,.08,1L82.68,203,84,205a0.83,0.83,0,0,1,0,.95l-1.26,1.9,1.31,2a0.84,0.84,0,0,1,0,.9l-1.28,1.84,1.21,1.69a1.55,1.55,0,0,1,.22.93v3.25A10.44,10.44,0,0,1,80,238.56ZM79.44,216.8V201.39a0.71,0.71,0,0,0-1.43,0V216.8A0.71,0.71,0,1,0,79.44,216.8ZM80,234.39a3.33,3.33,0,1,0-3.33-3.33A3.33,3.33,0,0,0,80,234.39Z"/>
        </g>
        <circle className="doorright-cls-14" cx="80" cy="173.75" r="9"/>
        <circle id="indicator_closed" className="doorright-cls-15" cx="80" cy="173.75" r="7"/>
        <circle id="indicator_open" className="doorright-cls-16" cx="80" cy="173.75" r="7"/>
      </a>
    </DoorRight>
  )
}
