import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import RoomMock from "../components/RoomMock"
import MeoDoors from "../components/meo/MeoDoors"

export default function RoomTemplate5({
  data: { ciceronYaml },
  pageContext: { lang, pagePath, translations }
}) {
  const intl = useIntl()

  return (
    <RoomMock
      lang={lang}
      path={pagePath}
      room={5}
      title={intl.formatMessage({ id: "roomHeading5" })}
      shareImage={{
        url: `/assets/share-images/NW_Delning7_MEO_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
        width: 1200,
        height: 630,
      }}
      ciceronData={ciceronYaml}
      translations={translations}
    >
      <MeoDoors lang={lang} />
    </RoomMock>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    ciceronYaml(lang: { eq: $lang }, _id: { eq: "rum-5" }) {
      text
      sound
    }
  }
`
