import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import siteConfig from "../../site-config.json"

import MeoDoorLeft from "./MeoDoorLeft"
import MeoDoorRight from "./MeoDoorRight"

import MeoLogoSv from "../../images/meo/MEO_Logo_Sve.svg"
import MeoLogoEn from "../../images/meo/MEO_Logo_Eng.svg"
import meoDoorBg from "../../images/meo/meo-doors-background.png"

const Wrapper = styled.div`
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 75px;
  left: 0;
  right: 0;
  width: 90%;
  height: calc(100vh - 75px - 4.5rem);
  margin-top: 4.5rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (orientation: landscape) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    max-width: 40em;
  }

  &.is-inactive {
    opacity: 0.5;
  }
`
const MeoLogo = styled.img`
  width: 70%;
  margin-bottom: 2rem;
  max-width: 250px;

  @media screen and (orientation: landscape) {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 1;
    order: 2;
    align-self: center;
    margin-left: 4rem;
    margin-right: 4rem;
    max-width: 350px;
  }

  @media (max-width: 768px) and (orientation: landscape) {
    max-width: 50%;
  }
`

const Doors = styled.div`
  display: block;
  padding: 0;
  background:
    linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
    url(${meoDoorBg}?nf_resize=fit&w=400) no-repeat center center;
  background-size: cover;
  border: 1rem solid #0099AF;
  border-bottom: none;
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 400px;
  /* max-height:100%; */
  transform-origin: right;
  perspective: 500px;

  ${props => props.isopened && `
    cursor: pointer;
  `}

  svg {
    width: 20vh;
    height: auto;
    max-height: 100%;
    flex-shrink: 2;
    margin-right: 2px
  }

  #meo-DoorRight {
    margin-right: -5px;
  }

  #meo-DoorLeft {
    margin-left: -5px;
  }

  @media screen and (orientation: landscape) {
    order: 1;
    margin-left: 5%;
  }
`

const MeoDoors = ({ lang }) => {
  const [doorsOpen, setDoorsOpen] = useState(false)
  const handleDoorClick = (e) => {
    e.preventDefault()
    !doorsOpen ? setDoorsOpen(true) : navigate(`/${lang}/${siteConfig.meoPath[lang]}`)
  }

  return (
    <Wrapper>
      <MeoLogo src={lang === "sv" ? MeoLogoSv : MeoLogoEn} alt="" />
      <Doors onClick={handleDoorClick} isopened={doorsOpen} role={doorsOpen ? "button" : null}>
        <MeoDoorLeft />
        <MeoDoorRight isOpened={doorsOpen} onClick={handleDoorClick} role="button" />
      </Doors>
    </Wrapper>
  )
}

export default MeoDoors
